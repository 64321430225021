<template>
  <div>
    <div
      class="baseCard"
      style="margin-top:20px;"
    >
      <p style="
font-size: 24px;
font-weight: bold;
color: #555454;
line-height: 48px;text-align:center;margin-top:20px">{{detailData.title}}</p>
<div class="introInfo">
        <span>录入时间：{{detailData.publishDate}}</span>
        <span>浏览次数：{{detailData.views}}次</span>
      </div>
      <video
        style="width:100%;height:100%;margin-top:20px"
        :src="detailData.video"
		controls="controls"
		autoplay="autoplay"
      ></video>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      detailData: {},
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getDetail(this.$route.query.id);
  },

  methods: {
    getDetail(id) {
      apiUrls.selfDynamicDetail({ id }).then((res) => {
        this.detailData = res.results.data;
        console.log('获取到的详情页视频', this.detailData)
        // this.addViews(res.results.data.id);
      });
    },
    // addViews(id) {
    //   apiUrls.addViews({ id });
    // },
  },
};
</script>
<style lang="less" scoped>
.introInfo {
  width: 600px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  span {
    font-size: 14px;
    color: #999999;
  }
}
.detailContent {
  margin-top: 20px;
  padding: 20px 40px;
  line-height: 30px;
}
</style>